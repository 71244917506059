<template>
  <!-- vignette -->
  <img v-if="!mobile" id="vignette-left" src="room/blurs/greyy.png"/>
  <img v-else id="vignette-top" src="room/blurs/greyy-mobile.png">
  <img v-if="!mobile" id="vignette-right" src="room/blurs/greyy.png"/>
  <img v-else id="vignette-bottom" src="room/blurs/greyy-mobile.png">

  <!-- garden area -->
  <div id="tracking" :class="{ 'before-activation': !activated }">
    <div id="container">
      <img id="background" :src="'room/5023.png'" />
      <!-- randomly located list rendering of objects uploaded in the room -->
      <li v-for="(value, key, index) in objectCollection" :style="{ left: (Math.random()*79) + 6 + '%', top: (Math.random()*67) + 6 + '%' }" :key="key">
        
        <!-- image-bit element -->
        <div v-if="value.fieldset == 'background-image'" class="dot" @click="hideShowBit(index)"></div>
        <img v-if="value.fieldset == 'background-image'" :src="this.baseURL + value.id + '/' + value.cover" :class="{ hide : value.revealed != 'visible'}" :id="'bit-'+index"/>

        <!-- vimeo-bit element -->
        <div v-if="value.fieldset == 'vimeo-video'" class="dot vimeo" :id="'dot-'+index" @click="hideShowVimeo(index)">
          <div class="ignore"></div>
        </div>
        <iframe v-if="value.fieldset == 'vimeo-video'" :class="{ hide : value.revealed != 'visible'}" :id="index" :src="'https://player.vimeo.com/video/' + value.vimeo_url.match(`[0-9]{1,9}$`) + '?&byline=false&title=false&dnt=true&pip=false&autplay=true&portrait=false&controls=false&h=17df4b52bf'" width="480" height="280" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

        <!-- text-bit element -->
        <div v-if="value.fieldset == 'text-bit'" class="dot" @click="hideShowBit(index)"></div>
        <div v-if="value.fieldset == 'text-bit'" class="p-container" :class="{ large : value.css_layout == '1', hide : value.revealed != 'visible' }" :id="'bit-'+index" v-html="value.text_bit">
        </div>

        <!-- sound-bit element -->
        <div v-if="value.fieldset == 'sound-bit'" :id="'dot-'+value.uuid" class="dot sound" @click="playSoundBit(value.uuid)">
          <div class="ignore"></div>
        </div>
        <audio v-if="value.fieldset == 'sound-bit'" :id="value.uuid">
          <source :src="this.baseURL + value.id + '/' + value.url" type="audio/mpeg">
        </audio>
        
      </li>
    </div>
    <div id="overlay" v-show="activated">
      <!-- <Navigation prev="/" next="" /> -->
    </div>
    <!-- <Buttons v-show="activated" /> -->
  </div>

  <div id="sound-container">
    <!-- audio files will be appended here -->
  </div>
</template>

<script>
// import Navigation from "./Navigation.vue";
// import Buttons from "./Buttons.vue";
import axios from "axios";
import Player from "@vimeo/player";
import interact from "interactjs";

export default {
  components: {
    // Buttons,
  },
  data() {
    return {
      room: null,
      cursorArea: null,
      center: null,
      navigation: null,
      overlay: null,
      soundCollection: null,
      objectCollection: {},
      token: "522ed707c8c8490f117c3d037998f601",
      baseURL: process.env.VUE_APP_BASE_URL + "cms/project/media/entries/",
      transitionTimeOut: true
    };
  },
  computed: {
    activated() {
      return this.$store.state.activated;
    },
    imageUrl() {
      return this.room.image.url;
    },
    userVolume() {
      return this.$store.state.volume;
    },
    mobile() {
      return this.$store.state.mobile;
    }
  },
  watch: {
    activated(val) {
      if (val == true) {
        this.startAudioContext();
        this.navigation.fadeInAudio(this.soundCollection, this.userVolume);
      }
    },
    userVolume(newVol) {
      if (this.activated == true) {
        this.navigation.changeVolume(newVol, this.soundCollection);
      }
    }
  },
  created() {
    this.fetchRoom();
  },
  mounted() {

  interact('.dot.sound')
    .resizable({
      ignoreFrom: '.ignore',
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },

      cursorChecker (action) {
        // the library uses biderectional arrows <-> by default,
        // but we want specific arrows (<- or ->) for each diriection
        if (action.edges.top) { return "var(--resize-cursor)" }
        if (action.edges.bottom) { return "var(--resize-cursor)" }
        if (action.edges.left) { return "var(--resize-cursor)" }
        if (action.edges.right) { return "var(--resize-cursor)" }
      },

      listeners: {
        move (event) {
          var target = event.target

          // update the element's style
          target.style.width = (event.rect.width * 1.5) - 20 + 'px'
          target.style.height = (event.rect.height * 1.5) - 20 + 'px'

          let id = target.id;
          let slicedId = id.slice(4);
          let sound = document.getElementById(slicedId);
          sound.volume = (event.rect.width) / 100;

          target.style.transform = 'translate(-' + ((event.rect.width * 1.5 - 60) / 2) + 'px, -' + ((event.rect.height * 1.5 - 60) / 2) + 'px)'
        }
      },
      modifiers: [
        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 40, height: 40 },
          max: { width: 100, height: 100 }
        }),
        interact.modifiers.aspectRatio({ratio: "preserve"})
      ]
    })


    interact('.dot.vimeo')
    .resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },
      ignoreFrom: '.ignore',

      cursorChecker (action) {
        // the library uses biderectional arrows <-> by default,
        // but we want specific arrows (<- or ->) for each diriection
        if (action.edges.top) { return "var(--resize-cursor)" }
        if (action.edges.bottom) { return "var(--resize-cursor)" }
        if (action.edges.left) { return "var(--resize-cursor)" }
        if (action.edges.right) { return "var(--resize-cursor)" }
      },

      listeners: {
        move (event) {
          var target = event.target

          // update the element's style
          target.style.width = (event.rect.width * 1.5) - 20 + 'px'
          target.style.height = (event.rect.height * 1.5) - 20 + 'px'

          let id = target.id;
          let slicedId = id.slice(4);
          let sound = document.getElementById(slicedId);
          let vimeoPlayer = new Player(sound);
          vimeoPlayer.setVolume((event.rect.width) / 100);

          target.style.transform = 'translate(-' + ((event.rect.width * 1.5 - 60) / 2) + 'px, -' + ((event.rect.height * 1.5 - 60) / 2) + 'px)'

          sound.style.transform = 'translateY(-' + ((event.rect.height * 1.5 - 60)) + 'px)'

        }
      },
      modifiers: [
        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 40, height: 40 },
          max: { width: 100, height: 100 }
        }),
        interact.modifiers.aspectRatio({ratio: "preserve"})
      ]
    })

    // open elevator doors again
    this.$store.commit('elevatorDoorOpen', true);

    let container = document.getElementById("container");
    this.overlay = document.getElementById("overlay");

    const myTimeout = setTimeout(function() {
      // this.transitionTimeOut = false;
      container.classList.add("smooth-navigation");
      this.overlay.classList.add("smooth-navigation");
      clearTimeout(myTimeout);
    }.bind(this), 500);

    this.cursorArea = document.getElementById("tracking");
    this.navigation = new this.navigator(this.cursorArea);
  },
  methods: {
    hideShowBit(index) {
      let bit = document.getElementById('bit-'+index);
      bit.classList.toggle("hide");
    },
    hideShowVimeo(index) {
      let bit = document.getElementById(index);
      let vimeoPlayer = new Player(bit);
      
      vimeoPlayer.getPaused().then(function(paused) {
        if (paused) {
          vimeoPlayer.play();
          // remove if bits should all have their own volume
          // vimeoPlayer.setVolume(this.userVolume);
        } else {
          vimeoPlayer.pause();
        }
        bit.classList.toggle("hide");
      }.bind(this))
    },
    playSoundBit(uuid) {
      let soundBit = document.getElementById(uuid);
      let soundDot = document.getElementById("dot-" + uuid);

      if (soundBit.paused && soundBit.currentTime >= 0) {
        soundBit.play();
        soundDot.classList.add("playing");
      } else {
        soundBit.pause();
        soundDot.classList.remove("playing");
      }
    },
    async fetchRoom() {
      axios
        .all([
          this.room_request(), //or direct the axios request
          this.sound_request(),
          this.collection_request()
        ])
        .then(
          axios.spread((room_response, sound_response, collection_response) => {
            this.room = room_response.data.data;
            this.soundCollection = sound_response.data.data;

            for (const key in collection_response.data.data) {
              // filter for objects that are visible (and not a draft or hidden)
              if (collection_response.data.data[key].visibility == "visible") {
                this.objectCollection[key] = collection_response.data.data[key];
              }
            }

            // running the navigation from the navigation class
            let container = document.getElementById("container");
            let overlay = document.getElementById("overlay");

            this.navigation.initRoom(container, overlay, this.room, this.mobile);

            // when coming from another space, check if activation is already set to true
            if (this.activated == true) {
              this.startAudioContext();
              this.navigation.fadeInAudio(
                this.soundCollection,
                this.userVolume
              );
            }
          })
        );
    },
    room_request() {
      return axios({
        method:'get',
        url:`cms/api/entries?id=spaces/space1&[options]&token=${this.token}`,
        baseURL: process.env.VUE_APP_BASE_URL,
      })
    },
    sound_request() {
      return axios({
        method:'get',
        url: `cms/api/entries?id=spaces/space1&options[collection]=true&options[filter][where][0][key]=fieldset&options[filter][where][0][operator]=eq&options[filter][where][0][value]=ambient-sound&token=${this.token}`,
        baseURL: process.env.VUE_APP_BASE_URL,
      })  
    },
    collection_request() {
      return axios({
        method:'get',
        url: `cms/api/entries?id=spaces/space1&options[collection]=true&token=${this.token}`,
        baseURL: process.env.VUE_APP_BASE_URL,
      })
    },
    startAudioContext() {
      // initation sounds
      var soundContainer = document.getElementById("sound-container");

      this.navigation.initSound(
        soundContainer,
        this.soundCollection,
        this.userVolume
      );
    },
  },
};
</script>

<style>
#vignette-left, #vignette-right {
  position:fixed;
  z-index:5;
  pointer-events: none;
  top:0;
  width:auto;
  height:100%;
}

#vignette-right {
  right:0;
  transform: rotate(180deg);
}

#vignette-top, #vignette-bottom {
  position:fixed;
  z-index:5;
  pointer-events: none;
  width:100%;
  height:auto;
}

#vignette-bottom {
  bottom:0;
  transform: rotate(180deg);
}

li {
  position:absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li img {
  width: max(300px, 25vw);
  height:auto;
  margin-top:-20px;
  margin-left:20px;
}

li .p-container {
  width: max(300px, 30vw);
  margin-top:0;
  margin-left:40px;
  user-select: none;
}

li div p {
  font-size: var(--bits-p-font-size);
  z-index:5000;
}

li div.large p {
  font-size: var(--bits-p-large-font-size);
}

li iframe {
  margin-top:-20px;
  margin-left:20px;
}

li audio {
  width: max(200px, 25vw);
  margin-top:-20px;
  margin-left:20px;
}

li.revealed .hide {
  display: block;
}

li img.hide {
  display: none;
}

li div.hide {
  display: none;
}

li iframe.hide {
  display: none;
}

iframe {
  pointer-events: none;
}

.dot {
  position:relative;
  width:40px;
  height:40px;
  z-index:2;
  background-color: #003dff;
  opacity:0.8;
  /* animation: glow 1s ease-in-out infinite alternate; */
  box-shadow: 0 0 10px 10px #003dff;
  border-radius:100px;
}

.dot:hover {
    animation: glow 1s ease-in-out infinite alternate;
}

.dot.sound.playing {
  animation: glow 1s ease-in-out infinite alternate;
}

.dot.sound.playing .dot-volume {
  display: block;
}

.dot {
  cursor: var(--pointing-cursor);
}

.dot.sound, .dot.vimeo {
  border-width:10px;
  border-style:solid;
  border-color: transparent;
  /* touch-action: none; */
}

.dot .ignore {
  position:absolute;
  width:26px;
  height:26px;
  margin-left:-4px;
  margin-top:-4px;
  border-radius:10px;
  background-color:transparent;
}

#tracking {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: auto;
  /* overflow:hidden; */
}

#container {
  position:relative;
}

#container iframe, #container p, li img {
  animation: fade 2s;
}

#overlay, #container {
  animation: fade 3s;
}

#tracking.before-activation {
  pointer-events: none;
}

#background {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px 10px #003dff;
  }
  
  to {
    box-shadow: 0 0 18px 18px #003dff;
  }
}

@media all and (max-width: 850px) {

  li img {
    width: max(200px, 80vw);
    height:auto;
  }

}

</style>